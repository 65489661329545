import { useMediaQuery, useTheme } from '@mui/material'

export const useBreakpoints = () => {
  const theme = useTheme()
  const defaultBreakpoints = {
    lg: useMediaQuery(theme.breakpoints.down('lg')),
    md: useMediaQuery(theme.breakpoints.down('md')),
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  }
  return {
    breakpoints: defaultBreakpoints,
    useMediaQuery,
    from: theme.breakpoints.down,
  }
}
