import {
  Box,
  Container,
  AppBar,
  Slide,
  useScrollTrigger,
  Toolbar,
  styled,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { FC, ReactElement, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useBreakpoints } from 'utils/layout'
import { CountrySelector, Drawer, DrawerHandle, Logo } from './components'

const Link = styled(NavLink)(({ theme }) => ({
  fontSize: '18px',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  fontWeight: theme.fonts.weights.medium,
  color: theme.colors.secondary.mallard[500],
  '&.active': {
    fontWeight: theme.fonts.weights.semiBold,
    color: theme.colors.primary.coral[500],
    '&:hover': {
      color: theme.colors.primary.coral[500],
    },
  },
  '&:hover': {
    fontWeight: theme.fonts.weights.semiBold,
    color: theme.colors.secondary.mallard[700],
  },
  '&.sectionLink': {
    fontWeight: theme.fonts.weights.medium,
    color: theme.colors.secondary.mallard[500],
    '&:hover': {
      color: theme.colors.secondary.mallard[700],
    },
  },
}))

const BoxPadding = styled(Box)(({ theme }) => ({
  paddingBottom: '88px',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '80px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '52px',
  },
}))

const HideOnScroll: FC<{ children: ReactElement }> = ({ children }) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

const Navigation = () => (
  <Grid
    container
    component='nav'
    alignItems='center'
    sx={{
      xs: { display: 'none' },
      flexWrap: 'nowrap',
      gap: '40px',
    }}
  >
    {buttons.map(({ text, link }) => (
      <Grid
        sx={{
          minWidth: '30px',
          '&:last-child': {
            marginRight: 0,
          },
        }}
        key={text}
        container
        justifyContent='flex-end'
      >
        <Link to={link} className={link.includes('#') ? 'sectionLink' : ''}>
          {text}
        </Link>
      </Grid>
    ))}

    <CountrySelector />
  </Grid>
)

export const buttons = [
  { text: '', link: '' },
  { text: 'Our mission', link: '/our-mission' },
  { text: 'Get in touch', link: '/get-in-touch' },
]

export const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { breakpoints: bps } = useBreakpoints()
  const handleDrawerToggle = () => setDrawerOpen(state => !state)

  return (
    <BoxPadding>
      <HideOnScroll>
        <AppBar sx={{ backgroundColor: 'white' }}>
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              <Grid container width='100%'>
                <Grid xs={4} lg={4} container>
                  <NavLink draggable='false' to='/'>
                    <Logo />
                  </NavLink>
                </Grid>
                <Grid xs={8} lg={8} container justifyContent='flex-end'>
                  {bps.md ? (
                    <DrawerHandle handle={handleDrawerToggle} />
                  ) : (
                    <Navigation />
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Drawer open={drawerOpen} handle={handleDrawerToggle} buttons={buttons} />
    </BoxPadding>
  )
}
