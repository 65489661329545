import { Typography as MuiTypography } from '@mui/material'
import { get } from 'lodash'
import { FC } from 'react'
import { TypographyProps } from './types'
import { toMediaQuery } from './utils/toMediaQuery'

export const Typography: FC<TypographyProps> = ({
  variant,
  fontWeight,
  color,
  breakpoints,
  sx,
  component,
  children,
  ...typographyProps
}) => (
  <MuiTypography
    component={component ?? 'div'}
    sx={theme => ({
      color: get(theme.colors, color || '', 'shades.black'),
      fontWeight: fontWeight
        ? theme.fonts.weights[fontWeight]
        : theme.fonts.weights.normal,
      ...(variant ? theme.fonts.variants[variant] : theme.fonts.variants.h1),
      ...(breakpoints && toMediaQuery(breakpoints, theme)),
      ...(sx && typeof sx === 'function' ? sx(theme) : sx),
    })}
    {...typographyProps}
  >
    {children}
  </MuiTypography>
)
