import { useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { forwardRef, ComponentType } from 'react'
import { TextField, TextFieldProps } from './TextField'

type Props = TextFieldProps | ((theme: Theme) => TextFieldProps)

export const withTextField = (
  initialProps: Props,
  component?: ComponentType
) => {
  const Component = component ?? TextField
  const HOC = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const theme = useTheme()
    const withProps =
      typeof initialProps === 'function' ? initialProps(theme) : initialProps
    return <Component ref={ref} {...withProps} {...props} />
  })
  HOC.displayName = 'WithTextField'
  return HOC
}
