const headings = {
  h1: {
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-0.02em',
  },
  h2: {
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.02em',
  },
  h4: {
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '-0.02em',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-0.02em',
  },
  h7: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
}

const displays = {
  d1: {
    fontSize: '96px',
    lineHeight: '100px',
    letterSpacing: '-0.02em',
  },
  d2: {
    fontSize: '72px',
    lineHeight: '76px',
    letterSpacing: '-0.02em',
  },
  d3: {
    fontSize: '52px',
    lineHeight: '56px',
    letterSpacing: '-0.02em',
  },
  d4: {
    fontSize: '44px',
    lineHeight: '48px',
    letterSpacing: '-0.02em',
  },
}

const paragraphs = {
  p1: {
    fontSize: '18px',
    lineHeight: '28px',
  },
  p2: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  p3: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  p4: {
    fontSize: '12px',
    lineHeight: '20px',
  },
}

export const variants = {
  ...headings,
  ...displays,
  ...paragraphs,
}
