import { FC, ComponentType } from 'react'
import { Typography } from './Typography'
import { TypographyProps } from './types'

type Props = Omit<TypographyProps, 'children'>

export const withTypography = (
  initialProps: Props,
  component?: ComponentType
) => {
  const Component = component ?? Typography
  const WithTypography: FC<TypographyProps> = props => (
    <Component {...initialProps} {...props} />
  )
  return WithTypography
}
