import { Link as MuiLink } from '@mui/material'
import { FC } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { SxProps } from 'types/utils'

export type LinkProps = RouterLinkProps & {
  sx?: SxProps
}

export const Link: FC<LinkProps> = ({ sx, ...props }) => (
  <MuiLink
    component={RouterLink}
    sx={theme => ({
      color: 'inherit',
      textDecoration: 'none',
      '&:visited': {
        color: 'inherit',
      },
      ...(sx && typeof sx === 'function' ? sx(theme) : sx),
    })}
    {...props}
  />
)
