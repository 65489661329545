import { Tab, styled } from '@mui/material'

export const TabsSelector = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  textAlign: 'initial',
  display: 'flex',
  alignItems: 'flex-start',
  fontWeight: '700',
  marginRight: '32px',
  padding: '12px 0px',
  fontSize: '18px',
  color: theme.colors.shades['850'],
  [theme.breakpoints.down('md')]: { fontSize: '16px' },
  minWidth: '45px',
})) as typeof Tab
