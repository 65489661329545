import { Theme } from '@mui/material/styles'

export const sizes = {
  normal: (_theme: Theme) => ({
    fontSize: '18px',
    lineHeight: '28px',
    width: '168px',
    height: '48px',
    borderRadius: '24px',
  }),
  big: (theme: Theme) => ({
    fontSize: '18px',
    lineHeight: '28px',
    width: '230px',
    height: '55px',
    borderRadius: '36px',
    [theme.breakpoints.down('md')]: {
      width: '216px',
      height: '48px',
    },
  }),
}
