import { East } from '@mui/icons-material'
import { Variants } from '../types'

export const variants: Variants = {
  submit: () => ({
    size: 'normal',
    color: 'secondary',
    fontWeight: 600,
    endIcon: <East />,
    children: <>Let&apos;s go</>,
    type: 'submit',
  }),
}
