import { Container, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { event } from 'react-ga'
import logoMD from 'assets/images/logo-footer-md.svg'
import logoSM from 'assets/images/logo-footer-sm.svg'
import logoXL from 'assets/images/logo-footer-xl.svg'
import {
  Icon,
  Link as SystemLink,
  LinkProps as SystemLinkProps,
} from 'components'
import { useBreakpoints } from 'utils/layout'

const Link = (props: SystemLinkProps) => (
  <SystemLink
    sx={theme => ({
      color: theme.colors.shades.white,
      '&:visited': {
        color: theme.colors.shades.white,
      },
      fontSize: '16px',
      lineHeight: '24px',
    })}
    {...props}
  />
)

const handleSocialLinks = (name: string) => () =>
  event({
    category: 'Social',
    action: `Click - ${name}`,
    label: 'Social link clicked',
  })

const SocialLinks = () => (
  <>
    <Link
      to='https://www.instagram.com/paradisemobile5g'
      target='_blank'
      rel='noopener'
      onClick={handleSocialLinks('instagram')}
    >
      <Icon iconName='instagram' />
    </Link>
    <Link
      to='https://www.facebook.com/ParadiseMobile5G'
      target='_blank'
      rel='noopener'
      onClick={handleSocialLinks('facebook')}
    >
      <Icon iconName='facebook' />
    </Link>
    <Link
      to='https://www.linkedin.com/company/paradise-mobile'
      target='_blank'
      rel='noopener'
      onClick={handleSocialLinks('linkedin')}
    >
      <Icon iconName='linkedin' />
    </Link>
  </>
)

const Links = () => (
  <>
    <Grid container justifyContent='flex-end' columns={12}>
      <Grid sm={2}>
        <Link to='/our-mission'>Our mission</Link>
      </Grid>

      <Grid sm={2} container justifyContent='flex-end'>
        <Link to='/get-in-touch'>Get in touch</Link>
      </Grid>
    </Grid>
    <Box
      component='hr'
      sx={theme => ({
        color: theme.colors.secondary.mallard[300],
        mt: '32px',
      })}
    />
    <Grid container sx={{ mt: '32px' }}>
      <Grid xs={6}>
        <Box
          sx={{
            color: 'white',
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 600,
          }}
        >
          Let&apos;s connect!
        </Box>
      </Grid>
      <Grid xs={6} container justifyContent='space-around'>
        <SocialLinks />
      </Grid>
    </Grid>
  </>
)

const Logo = () => {
  const md = useBreakpoints().breakpoints.md
  return <img alt='logo-footer' src={md ? logoMD : logoXL} />
}

const FooterLarge = () => (
  <Grid
    container
    sx={theme => ({
      pt: '64px',
      pb: '72px',
      [theme.breakpoints.down('md')]: {
        pt: '48px',
        pb: '48px',
      },
      [theme.breakpoints.down('lg')]: { mx: '98px' },
      [theme.breakpoints.down(740)]: { mx: '30px' },
      [theme.breakpoints.down('sm')]: { mx: '16px' },
    })}
  >
    <Grid lg={5} md={4} sm={3}>
      <Link to='/'>
        <Logo />
      </Link>
    </Grid>
    <Grid lg={7} md={8} sm={9}>
      <Links />
    </Grid>
  </Grid>
)

const FooterMobile = () => (
  <Grid pt='40px' pb='64px' px='16px'>
    <Grid>
      <Grid xs={12}>
        <Link to='/'>
          <img alt='logo-footer' src={logoSM} />
        </Link>
      </Grid>
    </Grid>
    <Grid
      columns={2}
      spacing='8px'
      columnSpacing='0px'
      container
      mb='8px'
      mt='16px'
    >
      <Grid xs={1}>
        <Link to='/our-mission'>Our mission</Link>
      </Grid>
      <Grid xs={1}>
        <Link to='/get-in-touch'>Get in touch</Link>
      </Grid>
    </Grid>
    <Box
      component='hr'
      sx={theme => ({
        color: theme.colors.secondary.mallard[300],
        my: '24px',
      })}
    />
    <Grid mb='16px'>
      <Box
        sx={{
          color: 'white',
          fontSize: '24px',
          lineHeight: '32px',
          fontWeight: 600,
        }}
      >
        Let&apos;s connect!
      </Box>
    </Grid>
    <Grid container sx={{ '& a': { mr: '24px' } }}>
      <SocialLinks />
    </Grid>
  </Grid>
)

export const Footer = () => {
  const sm = useBreakpoints().breakpoints.sm
  return (
    <Container
      maxWidth='xxl'
      disableGutters
      sx={theme => ({
        background: theme.colors.secondary.mallard[500],
      })}
    >
      <Container maxWidth='lg'>
        {sm ? <FooterMobile /> : <FooterLarge />}
      </Container>
    </Container>
  )
}
