import { Box } from '@mui/material'
import logo from 'assets/images/logo-pink-horizontal.svg'

const logoSizes = {
  sm: {
    width: '134px',
    height: '20px',
  },
  xl: {
    width: '215px',
    height: '32px',
  },
}

export const Logo = () => (
  <Box
    component='img'
    alt='logo'
    src={logo}
    sx={theme => ({
      position: 'relative',
      top: '7px',
      ...logoSizes.xl,
      [theme.breakpoints.down('sm')]: {
        ...logoSizes.sm,
      },
    })}
  />
)
