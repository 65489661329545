import { Tabs as MuiTabs } from '@mui/material'
import { FC, SyntheticEvent } from 'react'
import { TabsSelector } from './TabsSelector'

type Props = {
  value: number
  onChange: (event: SyntheticEvent, tabId: number) => void
  tabs: { value: number; label: string }[]
}

export const Tabs: FC<Props> = ({ onChange, tabs, value }) => (
  <MuiTabs
    value={value}
    onChange={onChange}
    textColor='primary'
    indicatorColor='secondary'
    sx={{
      fontStyle: 'normal',
      mb: '30px',
    }}
    aria-label='tabSelector'
  >
    {tabs.map(({ value, label }) => (
      <TabsSelector value={value} label={label} key={value} />
    ))}
  </MuiTabs>
)
