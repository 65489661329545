import { Box, styled } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Footer } from './components/Footer/Footer'
import { Navbar } from './components/Navbar/Navbar'

type Props = {
  children: ReactNode
}

const StickyFooter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100svh',
})

export const Layout: FC<Props> = ({ children }) => (
  <StickyFooter>
    <Box sx={{ flex: '1', overflowX: 'hidden' }}>
      <Navbar />
      <Box component='main'>{children}</Box>
    </Box>
    <Footer />
  </StickyFooter>
)
