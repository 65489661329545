import { Theme } from '@mui/material/styles'
import { reduce } from 'lodash'
import { Variant } from 'themes/types'
import { Breakpoints, Breakpoint } from '../types'

export const toMediaQuery = (breakpoints: Breakpoints, theme: Theme) =>
  reduce(
    breakpoints,
    (acc, variant, bp) => ({
      ...acc,
      [theme.breakpoints.down(bp as Breakpoint)]:
        theme.fonts.variants[variant as Variant],
    }),
    {}
  )
