import { FC } from 'react'
import { ReactComponent as Facebook } from './icons/facebook.svg'
import { ReactComponent as Gears } from './icons/gears.svg'
import { ReactComponent as Handshake } from './icons/handshake.svg'
import { ReactComponent as Heart } from './icons/heart.svg'
import { ReactComponent as Instagram } from './icons/instagram.svg'
import { ReactComponent as Linkedin } from './icons/linkedin.svg'
import { ReactComponent as MagicWand } from './icons/magic-wand.svg'
import { ReactComponent as Pig } from './icons/pig.svg'
import { ReactComponent as Speedometer } from './icons/speedometer.svg'
import { ReactComponent as Stars } from './icons/stars.svg'
import { ReactComponent as ThumbUp } from './icons/thumb-up.svg'
import { ReactComponent as Twitter } from './icons/twitter.svg'

type Props = {
  iconName: keyof typeof icons
}

const icons = {
  pig: Pig,
  speedometer: Speedometer,
  heart: Heart,
  magicWand: MagicWand,
  thumbUp: ThumbUp,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  stars: Stars,
  handshake: Handshake,
  gears: Gears,
}

export const Icon: FC<Props> = ({ iconName, ...props }) => {
  const IconSvg = icons[iconName]
  return <IconSvg {...props} />
}
