import { Theme } from '@mui/material/styles'
import { Colors } from '../types'

export const colors: Colors = {
  primary: (theme: Theme) => ({
    color: theme.colors.shades.white,
    bgcolor: theme.colors.primary.driftwood['500'],
  }),
  secondary: (theme: Theme) => ({
    color: theme.colors.shades.white,
    bgcolor: theme.colors.secondary.mallard['500'],
  }),
}
