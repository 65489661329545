import {
  TextField as MuiTextField,
  TextFieldProps,
  formHelperTextClasses,
  inputLabelClasses,
  inputClasses,
  Theme,
} from '@mui/material'
import { forwardRef } from 'react'
import { SxProps } from 'types/utils'

type Props = TextFieldProps & {
  sx?: SxProps
  helperColor?: string
}

const InputProps = {
  sx: {
    '& input': {
      padding: '3px 0 4px',
    },
    [`&.${inputClasses.root}`]: {
      mt: '26px',
      px: '8px',
    },
    [`&.${inputClasses.root}::before`]: {
      borderBottom: 'none',
    },
    [`&.${inputClasses.root}:hover::before`]: {
      borderBottom: '1px solid #6B7280',
    },
    [`&.${inputClasses.root}:hover::after`]: {
      borderBottom: '1px solid #6B7280',
    },
    [`&.${inputClasses.root}::after`]: {
      borderBottom: '1px solid grey',
    },
  },
}

const InputLabelProps = {
  sx: (theme: Theme) => ({
    left: '44px',
    [`&.${inputLabelClasses.shrink}`]: {
      transform: 'translate(0, 8px) scale(0.75)',
      color: '#00000099',
    },
    [`&.${inputLabelClasses.shrink}.Mui-error`]: {
      color: theme.colors.destructive[600],
    },
  }),
}

const TextField = forwardRef<HTMLDivElement, Props>(
  ({ sx, helperColor, ...props }: Props, ref) => (
    <MuiTextField
      ref={ref}
      variant='standard'
      sx={theme => ({
        bgcolor: 'white',
        borderRadius: '36px',
        width: '100%',
        padding: '0 36px',
        height: '56px',
        [`& .${formHelperTextClasses.root}.Mui-error`]: {
          color: helperColor || theme.colors.shades.white,
        },
        ...(sx && typeof sx === 'function' ? sx(theme) : sx),
      })}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      {...props}
    />
  )
)
TextField.displayName = 'TextField'

export { TextField }
export type { Props as TextFieldProps }
