import LanguageIcon from '@mui/icons-material/Language'
import { Box, Button, MenuItem, Popover, styled } from '@mui/material'
import { useState, MouseEvent as ReactMouseEvent } from 'react'

const Links = {
  Bermuda: 'https://paradisemobile.bm/',
  Cayman: 'https://paradisemobile.ky/',
}

const Link = styled('a')(({ theme }) => ({
  color: theme.colors.shades.black,
  fontSize: '18px',
  textDecoration: 'none',
}))

export const CountrySelector = () => {
  const [selectedOption, setSelectedOption] = useState('Cayman')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleButtonClick = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOptionSelect = (
    selectedOption: ReactMouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const userSelectOption = selectedOption.currentTarget
      .outerText as keyof typeof Links
    setAnchorEl(null)
    setSelectedOption(userSelectOption)
  }

  const isPopoverOpen = Boolean(anchorEl)

  return (
    <>
      <Button
        onClick={handleButtonClick}
        sx={theme => ({
          width: '36px',
          backgroundColor: theme.colors.neutral[100],
          borderRadius: '8px',
          minWidth: 'unset',
        })}
      >
        <LanguageIcon
          sx={theme => ({
            color: theme.colors.secondary.mallard[500],
          })}
        />
      </Button>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: '8px',
        }}
      >
        <Link href={Links.Bermuda}>
          <MenuItem
            disabled={selectedOption === 'Bermuda'}
            onClick={handleOptionSelect}
          >
            Bermuda
          </MenuItem>
        </Link>
        <Box
          sx={theme => ({
            width: '128px',
            borderBottom: `1px solid ${theme.colors.neutral[200]}`,
          })}
        />
        <Link href={Links.Cayman}>
          <MenuItem
            disabled={selectedOption === 'Cayman'}
            onClick={handleOptionSelect}
          >
            Cayman
          </MenuItem>
        </Link>
      </Popover>
    </>
  )
}
