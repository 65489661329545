import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  ScrollRestoration,
} from 'react-router-dom'
import { Layout } from 'components'

const router = createBrowserRouter([
  {
    element: (
      <>
        <Layout>
          <ScrollRestoration />
          <Outlet />
        </Layout>
      </>
    ),
    children: [
      {
        path: '/',
        lazy: async () => ({
          Component: (await import('pages')).Home,
        }),
      },
      {
        path: '/get-in-touch',
        lazy: async () => ({
          Component: (await import('pages')).ContactUs,
        }),
      },
      {
        path: '/our-mission',
        lazy: async () => ({
          Component: (await import('pages')).OurMission,
        }),
      },
    ],
  },
])

export const Router = () => <RouterProvider router={router} />
