import { Button as MuiButton, useTheme } from '@mui/material'
import { pickBy } from 'lodash'
import { FC, useMemo, isValidElement } from 'react'
import { getProps, getVariantProps } from './config/config'
import { Props } from './types'

export const Button: FC<Props> = ({
  variant,
  size,
  color,
  children,
  ...buttonProps
}) => {
  const theme = useTheme()

  const { sizeProps, colorProps, ...props } = useMemo(() => {
    const props = getProps(theme, size, color)
    return variant
      ? Object.assign(getVariantProps(theme, variant), pickBy(props))
      : props
  }, [theme, variant, size, color])

  return (
    <MuiButton
      disableElevation
      sx={{
        textTransform: 'none',
        '&:hover': {
          bgcolor: colorProps?.bgcolor,
        },
        ...colorProps,
        ...sizeProps,
      }}
      {...props}
      {...buttonProps}
    >
      {children
        ? children
        : 'children' in props && isValidElement(props.children)
        ? props.children
        : null}
    </MuiButton>
  )
}
