import { Theme } from '@mui/material/styles'
import { colors } from './colors'
import { sizes } from './sizes'
import { variants } from './variants'
import { Variant, Size, Color } from '../types'

export const getProps = (theme: Theme, size?: Size, color?: Color) => ({
  sizeProps: size ? sizes[size](theme) : null,
  colorProps: color ? colors[color](theme) : null,
})

export const getVariantProps = (theme: Theme, variant: Variant) => {
  const { size, color, ...props } = variants[variant](theme)
  return { ...getProps(theme, size, color), ...props }
}
