import { createTheme } from '@mui/material/styles'
import { colors, fonts } from './tokens'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'ProximaNova',
  },
  palette: {
    primary: {
      main: '#EC778F',
    },
    secondary: {
      main: '#E96679',
    },
    text: {
      primary: '#003743',
    },
  },
  colors,
  fonts: {
    variants: fonts.variants,
    weights: fonts.weights,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 908,
      lg: 1200,
      xl: 1440,
      xxl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: fonts.family.map(font => ({
          '@font-face': font,
        })),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: ({ theme }) => ({
          height: '88px',
          minHeight: '88px',
          [theme.breakpoints.down('md')]: {
            height: '80px',
            minHeight: '80px',
          },
          [theme.breakpoints.down('sm')]: {
            height: '52px',
            minHeight: '52px',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          '&:focus': {
            background: theme.colors.shades.white,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:focus-visible': {
            background: theme.colors.shades.white,
            color: '#3e6871',
          },
          '&:hover': {
            background: colors.shades[100],
            color: colors.shades.black,
          },
        }),
      },
    },
  },
})
