export const app = {
  port: process.env.PORT || '3000',
  environment: process.env.NODE_ENV || 'development',
  strapi: {
    url: process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337',
    token: process.env.REACT_APP_STRAPI_TOKEN,
  },
  zendeskUrl:
    process.env.REACT_APP_ZENDESK_URL || 'https://paradisemobile.zendesk.com',
  zendeskEmail: process.env.REACT_APP_ZENDESK_EMAIL,
  zendeskApiToken: process.env.REACT_APP_ZENDESK_API_TOKEN,
  websiteLocation: process.env.REACT_APP_WEBSITE_LOCATION || 'BM',
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  hotjarId: process.env.REACT_APP_HOTJAR_ID,
  hotjarSv: process.env.REACT_APP_HOTJAR_SV,
}
