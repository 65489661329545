import { Pagination as MuiPagination } from '@mui/material'
import { FC, ChangeEvent } from 'react'
import { useBreakpoints } from 'hooks/layout'

type PaginationProps = {
  currentPage: number
  pageCount: number | undefined
  siblingCount: number
  boundaryCount: number
  onChange: (event: ChangeEvent<unknown>, value: number) => void
  size?: 'small' | 'medium' | 'large'
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  pageCount = 1,
  siblingCount,
  boundaryCount,
  size,
  onChange,
}) => {
  const { breakpoints: bps } = useBreakpoints()
  const sizeProp = size || (bps.sm ? 'small' : bps.md ? 'medium' : 'large')
  return (
    <MuiPagination
      page={currentPage}
      count={pageCount}
      siblingCount={siblingCount}
      boundaryCount={boundaryCount}
      onChange={onChange}
      size={sizeProp}
      color='primary'
      sx={theme => ({
        '& .MuiPaginationItem-root': {
          color: theme.colors.primary.driftwood['500'],
          '&.Mui-selected': {
            color: theme.colors.shades.white,
          },
        },
        '& .MuiButtonBase-root': {
          border: `1px solid ${theme.colors.primary.driftwood['500']}`,
          '&.Mui-selected:hover': {
            color: theme.colors.shades.white,
            backgroundColor: theme.colors.primary.driftwood['500'],
          },
        },
      })}
    />
  )
}
