import { Menu } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { FC } from 'react'

type Props = {
  handle: () => void
}

export const DrawerHandle: FC<Props> = ({ handle }) => (
  <Grid container sx={{ display: { md: 'none' } }}>
    <IconButton
      disableTouchRipple
      onClick={handle}
      sx={{
        p: '0px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Menu
        sx={theme => ({
          color: theme.colors.secondary.mallard[500],
          position: 'relative',
          top: '3px',
        })}
      />
    </IconButton>
  </Grid>
)
