import { Drawer as DrawerMUI, Box, styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { FC } from 'react'
import { CountrySelector } from '../CountrySelector/CountrySelector'

type Props = {
  open: boolean
  handle: () => void
  buttons: {
    text: string
    link: string
  }[]
}

const Link = styled('a')(({ theme }) => ({
  color: theme.colors.secondary.mallard[500],
  fontSize: '18px',
  textDecoration: 'none',
}))

export const Drawer: FC<Props> = ({ open, handle, buttons }) => (
  <DrawerMUI
    anchor='right'
    open={open}
    onClose={handle}
    ModalProps={{ keepMounted: true }}
  >
    <Grid container sx={{ width: '320px' }} justifyContent='flex-end'>
      <Box onClick={handle} p='16px'>
        <Box sx={{ cursor: 'pointer' }}>X</Box>
      </Box>
    </Grid>
    <Grid mt='24px' mx='16px'>
      <Grid container justifyContent='end'>
        <CountrySelector />
      </Grid>
      {buttons.map(({ text, link }, index) => (
        <Box
          key={index}
          sx={theme => ({
            py: '16px',
            color: theme.colors.secondary.mallard[500],
            textAlign: 'center',
            '&:not(:last-child)': {
              borderBottom: '1px solid #C9FCFF',
            },
          })}
        >
          <Link href={link}>{text}</Link>
        </Box>
      ))}
    </Grid>
  </DrawerMUI>
)

export type DrawerProps = Props
