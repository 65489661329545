import {
  FormControl,
  inputClasses,
  formHelperTextClasses,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { forwardRef } from 'react'
import { SelectHelperText } from './SelectHelperText'
import { mountOptions, Option } from './utils/mountOptions'

type Props = {
  label: string
  error: boolean
  helperText: string | undefined
  options: Option[]
}

const Select = forwardRef(
  ({ label, error, helperText, options, ...formProps }: Props, ref) => (
    <>
      <FormControl
        variant='standard'
        sx={theme => ({
          bgcolor: theme.colors.shades.white,
          borderRadius: '36px',
          width: '100%',
          padding: '0 36px',
          [`& .${formHelperTextClasses.root}`]: {
            color: 'white',
          },
        })}
        error={error}
      >
        <InputLabel
          sx={{
            left: '44px',
            [`&.${inputLabelClasses.shrink}`]: {
              transform: 'translate(0, 0.5rem) scale(0.75)',
              color: '#00000099',
            },
          }}
        >
          {label}
        </InputLabel>
        <MuiSelect
          defaultValue=''
          ref={ref}
          label={label}
          error={error}
          variant='standard'
          sx={{
            [`&.${inputClasses.root}`]: {
              mt: '24px',
              px: '8px',
            },
            [`&.${inputClasses.root}:hover::before`]: {
              borderBottom: '1px solid #6B7280',
            },
            [`&.${inputClasses.root}::before`]: {
              borderBottom: 'none',
            },
            [`&.${inputClasses.root}::after`]: {
              borderBottom: '1px solid #6B7280',
            },
          }}
          {...formProps}
        >
          {options ? (
            mountOptions(options)
          ) : (
            <Grid container justifyContent='center' alignItems='center'>
              Loading
            </Grid>
          )}
          <MenuItem value='Other'>Other</MenuItem>
        </MuiSelect>
      </FormControl>
      <SelectHelperText error={helperText} />
    </>
  )
)
Select.displayName = 'FormSelect'

export { Select }
