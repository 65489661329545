import { MenuItem } from '@mui/material'

export type Option = {
  id: number
  value: string
}

export const mountOptions = <T extends Option>(options: T[]) =>
  options.map(({ id, value }) => (
    <MenuItem key={id} value={value}>
      {value}
    </MenuItem>
  ))
