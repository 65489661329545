import {
  FormHelperText as MuiFormHelperText,
  inputClasses,
} from '@mui/material'
import { FC } from 'react'

type Props = {
  error: string | undefined
}

export const SelectHelperText: FC<Props> = ({ error }) => (
  <MuiFormHelperText
    sx={theme => ({
      [`&.${inputClasses.underline}:after`]: {
        color: theme.colors.destructive['600'],
      },
      color: theme.colors.destructive['600'],
      position: 'relative',
      left: '38.4px',
    })}
  >
    {error ?? null}
  </MuiFormHelperText>
)
