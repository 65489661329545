import ProximaNovaBlack from '../../assets/fonts/proximaNovaBlack.woff2'
import ProximaNovaBold from '../../assets/fonts/proximaNovaBold.woff2'
import ProximaNovaLight from '../../assets/fonts/proximaNovaLight.woff2'
import ProximaNovaMedium from '../../assets/fonts/proximaNovaMedium.woff2'
import ProximaNovaRegular from '../../assets/fonts/proximaNovaRegular.woff2'
import ProximaNovaSemiBold from '../../assets/fonts/proximaNovaSemiBold.woff2'

const proximaNovaLight = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${ProximaNovaLight}) format('woff')`,
}

const proximaNovaRegular = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${ProximaNovaRegular}) format('woff')`,
}

const proximaNovaMedium = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${ProximaNovaMedium}) format('woff')`,
}

const proximaNovaSemiBold = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${ProximaNovaSemiBold}) format('woff')`,
}

const proximaNovaBold = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `url(${ProximaNovaBold}) format('woff')`,
}

const proximaNovaBlack = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `url(${ProximaNovaBlack}) format('woff')`,
}

export const family = [
  proximaNovaLight,
  proximaNovaRegular,
  proximaNovaMedium,
  proximaNovaSemiBold,
  proximaNovaBold,
  proximaNovaBlack,
]
