import React from 'react'
import 'lazysizes'
import { createRoot } from 'react-dom/client'
import { initialize } from 'react-ga'
import TagManager from 'react-gtm-module'
import { app } from 'config/app'
import { App } from './App'

initialize(String(app.gaId))
TagManager.initialize({ gtmId: String(app.gtmId) })

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
